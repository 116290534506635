import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {PermissionService} from '../../api/services/permission.service';
import {PermissionModel} from '../../models/permission.model';
import {Toaster} from '../../classes/toaster.class';
import {ActivatedRoute} from '@angular/router';
import {TeamService} from '../../api/services/team.service';
import {TeamModel, TeamPatchModel} from '../../models/response/team.model';
import {
    BUTTON_TYPE,
    EColumnSize,
    EColumnType,
    ESelectionMode,
    ESortOrder,
    ISortOptionEvent,
    ITableColumn, NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {SelectionModel} from '@angular/cdk/collections';
import {SortDirection} from '@angular/material/sort';
import {takeUntil} from 'rxjs/operators';
import {PaginatorService} from '../../components/paginator/paginator.service';

@Component({
    selector: 'om-team-permissions',
    templateUrl: './team-permissions.component.html',
    styleUrls: ['./team-permissions.component.scss'],
    providers: [PaginatorService]
})
export class TeamPermissionsComponent implements OnInit, OnDestroy {
    public tableId = 'team-permissions';
    public teamName: string;
    public teamPermissions: PermissionModel[];
    public items: PermissionModel[] = [];
    public pageIndex: number;
    public pageSize: number;
    public total: number;
    public sortProperty: string;
    public sortOrder: SortDirection;
    public columns: ITableColumn[] = [{
        title: 'Name',
        key: 'name',
        type: EColumnType.DEFAULT,
        sortProperty: 'name',
        size: EColumnSize.LARGE
    }, {
        title: 'Description',
        key: 'description',
        type: EColumnType.DEFAULT,
        sortProperty: 'description',
        size: EColumnSize.LARGE
    }];
    public ESelectionMode = ESelectionMode;
    public selection = new SelectionModel<PermissionModel>(true, [], false);
    public searchValue: string = '';
    public permissionsSubscription = new Subscription();
    public requestSubscription = new Subscription();
    private teamId: string;

    private onDestroySubject = new Subject<void>();

    constructor(private route: ActivatedRoute,
                private teamService: TeamService,
                private permissionService: PermissionService,
                private dialogService: NucDialogService,
                private paginatorService: PaginatorService) {
    }

    public ngOnInit(): void {
        this.teamId = this.route.snapshot.params.teamId;

        this.teamService.getTeam(this.teamId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((team) => {
                this.teamName = team.name;
                this.teamPermissions = team.permissions.map(permission => new PermissionModel(permission));
                this.setSelectedPermissions();
                this.setPageIndex();
            });

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getPermissions();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private getPermissions(): void {
        this.permissionsSubscription?.unsubscribe();
        this.permissionsSubscription = this.permissionService.getPermissions(this.pageSize,
            this.pageSize * (this.pageIndex - 1),
            this.sortProperty,
            this.sortOrder,
            this.searchValue).subscribe({
            next: results => {
                this.items = results.items;
                this.total = results.total;
            },
            error: Toaster.handleApiError
        });
    }

    private setSelectedPermissions(): void {
        this.selection.clear();
        this.selection.select(...this.teamPermissions);
    }

    /**
     * Set page index, default the first page
     *
     * @param {number} [pageIndex]
     */
    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex); // reset pageIndex
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = event.column?.sortProperty ? (event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc') : '';

        this.setPageIndex();
    }

    /**
     * Check if the confirm dialog needs to be shown on leaving the page
     * @return {boolean}
     */
    public canDeactivate(): boolean {
        if (!this.teamPermissions) {
            return true;
        }

        const selectedValues = this.selection.selected;

        return selectedValues.every(permission => this.teamPermissions.find(teamPermission => teamPermission._id === permission._id)) &&
            this.teamPermissions.every(teamPermission => selectedValues.find(selected => selected._id === teamPermission._id));
    }


    public openConfirmDialog(): void {
        const confirmDialogConfig = new NucDialogConfigModel('Update permissions', 'You are about to change permissions for the team. Are you sure?');
        const confirmDialog = this.dialogService.openDialog(confirmDialogConfig);
        confirmDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => confirmDialog.close());
        confirmDialogConfig.addAction('Yes', BUTTON_TYPE.PRIMARY).subscribe(() => {
            confirmDialog.close();
            this.updateTeamPermissions();
        });

    }

    /**
     * Puts all buffered permission changes
     * Clears permission change buffer on success
     */
    private updateTeamPermissions(): void {
        this.requestSubscription = this.teamService
            .patchTeam(this.teamId, {permissions: this.selection.selected.map(permission => permission._id)} as TeamPatchModel)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (team: TeamModel) => {
                    this.teamPermissions = team.permissions.map(permission => new PermissionModel(permission));
                    this.setSelectedPermissions();
                    Toaster.success('Successfully updated permissions for the team');
                },
                error: Toaster.handleApiError
            });
    }
}
