export default {
    groupedAssets: [
        {
            identifier: '2609185673682',
            s3Keys: [
                'demo data/images/product 2609185673682_1.png',
                'demo data/images/mood beef.jpg',
            ]
        },
        {
            identifier: '6051803358722',
            s3Keys: [
                'demo data/images/product 6051803358722_1.png',
            ]
        },
        {
            identifier: '6145402295362',
            s3Keys: [
                'demo data/images/product 6145402295362_1.png',
            ]
        },
        {
            identifier: '8993792246126',
            s3Keys: [
                'demo data/images/product 8993792246126_1.png',
            ]
        },
        {
            identifier: '7862643380720',
            s3Keys: [
                'demo data/images/product 7862643380720_1.png',
            ]
        },
        {
            identifier: '7797317896365',
            s3Keys: [
                'demo data/images/product 7797317896365_1.png',
            ]
        },
        {
            identifier: '6931803318722',
            s3Keys: [
                'demo data/images/product 6931803318722_1.png',
            ]
        },
        {
            identifier: '4808937204921',
            s3Keys: [
                'demo data/images/product 4808937204921_1.png',
            ]
        },
        {
            identifier: '5849810109696',
            s3Keys: [
                'demo data/images/product 5849810109696_1.png',
            ]
        },
        {
            identifier: '4320483614771',
            s3Keys: [
                'demo data/images/product 4320483614771_1.png',
            ]
        },
        {
            identifier: '9328701058837',
            s3Keys: [
                'demo data/images/product 9328701058837_1.png',
            ]
        },
        {
            identifier: '3663761762426',
            s3Keys: [
                'demo data/images/product 3663761762426_1.png',
                'demo data/images/product 3663761762426_2.png',
            ]
        },
        {
            identifier: '4830932740434',
            s3Keys: [
                'demo data/images/product 4830932740434_1.png',
                'demo data/images/product 4830932740434_2.png',
            ]
        },
        {
            identifier: '5016643591908',
            s3Keys: [
                'demo data/images/product 5016643591908_1.png',
                'demo data/images/product 5016643591908_2.jpg',
                'demo data/images/mood picnic1.jpg',
                'demo data/images/mood picnic2.jpg',
            ]
        },
        {
            identifier: '5684984016022',
            s3Keys: [
                'demo data/images/product 5684984016022_1.png',
                'demo data/images/product 5684984016022_2.png',
                'demo data/images/mood blueberrypancakes.jpg',

            ]
        },
        {
            identifier: '7939939211996',
            s3Keys: [
                'demo data/images/product 7939939211996_1.png',
            ]
        },
        {
            identifier: '1071165454502',
            s3Keys: [
                'demo data/images/product 1071165454502_1.png',
            ]
        },
        {
            identifier: '5313555880615',
            s3Keys: [
                'demo data/images/product 5313555880615_1.png',
            ]
        },
        {
            identifier: '3616294582447',
            s3Keys: [
                'demo data/images/product 3616294582447_1.png',
            ]
        },
        {
            identifier: '1327554350979',
            s3Keys: [
                'demo data/images/product 1327554350979_1.png',
            ]
        },
        {
            identifier: '4551345723894',
            s3Keys: [
                'demo data/images/product 4551345723894_1.png',
            ]
        },
        {
            identifier: '8047082458994',
            s3Keys: [
                'demo data/images/product 8047082458994_1.png',
            ]
        },
        {
            identifier: '5385081640139',
            s3Keys: [
                'demo data/images/product 5385081640139_1.png',
            ]
        },
        {
            identifier: '5385081640139',
            s3Keys: [
                'demo data/images/mood noodlebowls.jpg'
            ]
        },
        {
            identifier: '3983789588638',
            s3Keys: [
                'demo data/images/product 3983789588638_1.png',
            ]
        },
        {
            identifier: '3820649446823',
            s3Keys: [
                'demo data/images/product 3820649446823_1.png',
            ]
        },
        {
            identifier: '8161581410457',
            s3Keys: [
                'demo data/images/product 8161581410457_1.png',
                'demo data/images/product 8161581410457_2.png',
            ]
        },
        {
            identifier: '4318302352105',
            s3Keys: [
                'demo data/images/product 4318302352105_1.png',
            ]
        },
        {
            identifier: '7513799925686',
            s3Keys: [
                'demo data/images/product 7513799925686_1.png',
                'demo data/images/product 7513799925686_2.png',
            ]
        },
        {
            identifier: '5434335923904',
            s3Keys: [
                'demo data/images/product 5434335923904_1.png',
            ]
        },
        {
            identifier: '9674292057108',
            s3Keys: [
                'demo data/images/product 9674292057108_1.png',
            ]
        },
        {
            identifier: '4427811633091',
            s3Keys: [
                'demo data/images/product 4427811633091_1.png',
                'demo data/images/mood 4427811633091.png',
            ]
        },
        {
            identifier: '9213914949226',
            s3Keys: [
                'demo data/images/product 9213914949226_1.png',
                'demo data/images/mood 9213914949226.jpg',
            ]
        },
        {
            identifier: '4910107548004',
            s3Keys: [
                'demo data/images/product 4910107548004_1.png',
                'demo data/images/product 4910107548004_2.png',
            ]
        },
        {
            identifier: '7799245988862',
            s3Keys: [
                'demo data/images/product 7799245988862_1.png',
                'demo data/images/mood 7799245988862.jpg',
            ]
        },
        {
            identifier: '8078546347133',
            s3Keys: [
                'demo data/images/product 8078546347133_1.png',
            ]
        },
        {
            identifier: '4035134945811',
            s3Keys: [
                'demo data/images/product 4035134945811_1.png',
                'demo data/images/product 4035134945811_2.png',
                'demo data/images/product 4035134945811_3.png',
                'demo data/images/mood 4035134945811.jpg',
            ]
        },
        {
            identifier: '1353800843606',
            s3Keys: [
                'demo data/images/product 1353800843606_1.png',
                'demo data/images/product 1353800843606_2.png',
            ]
        },
        {
            identifier: '7813757990062',
            s3Keys: [
                'demo data/images/product 7813757990062_1.png',
            ]
        },
        {
            identifier: '6229987757183',
            s3Keys: [
                'demo data/images/product 6229987757183_1.png',
                'demo data/images/mood 6229987757183.png',
                'demo data/images/logo 6229987757183.png',
            ]
        },
        {
            identifier: '8083969616824',
            s3Keys: [
                'demo data/images/product 8083969616824_1.png',
                'demo data/images/mood winecollection.png',
            ]
        },
        {
            identifier: '7775800397408',
            s3Keys: [
                'demo data/images/product 7775800397408_1.png',
            ]
        },
        {
            identifier: '2034813621637',
            s3Keys: [
                'demo data/images/product 2034813621637_1.png',
                'demo data/images/product 2034813621637_2.png',
            ]
        },
        {
            identifier: '9926064809177',
            s3Keys: [
                'demo data/images/product 9926064809177_1.png',
                'demo data/images/product 9926064809177_2.png',
                'demo data/images/mood 9926064809177.jpg',
            ]
        },
        {
            identifier: '8730850620474',
            s3Keys: [
                'demo data/images/product 8730850620474_1.png',
                'demo data/images/product 8730850620474_2.png',
                'demo data/images/logo 8730850620474.png',
            ]
        },
        {
            identifier: '7345903588962',
            s3Keys: [
                'demo data/images/product 7345903588962_1.png',
                'demo data/images/product 7345903588962_2.png',
                'demo data/images/product 7345903588962_3.png',
            ]
        },
        {
            identifier: '2426687827771',
            s3Keys: [
                'demo data/images/product 2426687827771_1.png',
                'demo data/images/product 2426687827771_2.png',
            ]
        },
        {
            identifier: '7799245988862',
            s3Keys: [
                'demo data/images/mood granola-a.jpg',
                'demo data/images/mood granola-b.jpg',
                'demo data/images/mood granola-c.jpg',
                'demo data/images/logo eatnatural.png',
            ]
        },
        {
            identifier: '3999660785548',
            s3Keys: [
                'demo data/images/mood nesquikcollection.png',
            ]
        },
        {
            identifier: [
                '3663761762426',
                '4830932740434',
            ],
            s3Keys: [
                'demo data/images/mood benandjerrys.png',
            ]
        },
        {
            identifier: '4910107548004',
            s3Keys: [
                'demo data/images/mood cheese-a.jpg',
                'demo data/images/mood cheese-b.jpg',
                'demo data/images/mood cheese-c.png',
            ]
        },
        {
            identifier: '2034813621637',
            s3Keys: [
                'demo data/images/mood applepearcrate.psd',
            ]
        },
        {
            identifier: '3999660785548',
            s3Keys: [
                'demo data/images/product 3999660785548_1.png',
            ]
        },
        {
            identifier: '5358823967898',
            s3Keys: [
                'demo data/images/product 5358823967898_1.png',
                'demo data/images/product 5358823967898_2.png',
            ]
        },
        {
            identifier: '5373678673975',
            s3Keys: [
                'demo data/images/product 5373678673975_1.png',
                'demo data/images/product 5373678673975_2.png',
            ]
        },
        {
            identifier: '1516953224251',
            s3Keys: [
                'demo data/images/product 1516953224251_1.png',
                'demo data/images/mood 1516953224251.png',
            ]
        },
        {
            identifier: '8831877303610',
            s3Keys: [
                'demo data/images/product 8831877303610_1.png',
            ]
        },
        {
            identifier: '1891813412021',
            s3Keys: [
                'demo data/images/product 1891813412021_1.png',
            ]
        },
        {
            identifier: '1816743914408',
            s3Keys: [
                'demo data/images/product 1816743914408_1.png',
            ]
        },
        {
            identifier: '8613671589110',
            s3Keys: [
                'demo data/images/product 8613671589110_1.png',
            ]
        },
        {
            identifier: '2804617996053',
            s3Keys: [
                'demo data/images/product 2804617996053_1.png',
            ]
        },
        {
            identifier: '5814781473490',
            s3Keys: [
                'demo data/images/product 5814781473490_1.png',
                'demo data/images/product 5814781473490_2.png',
            ]
        },
        {
            identifier: '6715141735996',
            s3Keys: [
                'demo data/images/product 6715141735996_1.png',
            ]
        },
        {
            identifier: '8187598998985',
            s3Keys: [
                'demo data/images/product 8187598998985_1.png',
            ]
        },
        {
            identifier: '9993224634498',
            s3Keys: [
                'demo data/images/product 9993224634498_1.png',
            ]
        },
        {
            identifier: '8823411953988',
            s3Keys: [
                'demo data/images/product 8823411953988_1.png',
            ]
        },
        {
            identifier: '1223422572041',
            s3Keys: [
                'demo data/images/product 1223422572041_1.png',
                'demo data/images/product 1223422572041_2.png',
            ]
        },
        {
            identifier: '5186290527232',
            s3Keys: [
                'demo data/images/product 5186290527232_1.png',
                'demo data/images/product 5186290527232_2.png',
            ]
        },
        {
            identifier: '1213517148835',
            s3Keys: [
                'demo data/images/product 1213517148835_1.png',
                'demo data/images/product 1213517148835_2.png',
            ]
        },
        {
            identifier: '5591360826221',
            s3Keys: [
                'demo data/images/product 5591360826221_1.png',
                'demo data/images/product 5591360826221_2.png',
            ]
        },
        {
            identifier: '2202453318793',
            s3Keys: [
                'demo data/images/product 2202453318793_1.png',
                'demo data/images/mood 2202453318793.jpg',
            ]
        },
    ],
    identifier: 'SKU'
};
